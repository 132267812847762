'use client';
import { FC, ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import BaseSimpleBar from 'simplebar-react';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const SimpleBar: FC<Props> = ({
  className,
  children,
}) => {
  return (
    <BaseSimpleBar
      className={twMergeExt(cvaRoot(), className)}
    >
      {children}
    </BaseSimpleBar>
  );
};

const cvaRoot = cva(['SimpleBar-cvaRoot', ' ']);
