import { cva } from 'class-variance-authority';

export const cvaWrapper = cva([
  'relative',
  'not-last:mb-2',
  'group/input',
]);

export const cvaInner = cva('relative');

export const cvaLabel = cva([
  'mb-1.2',
  'text-bodyM',
  'pointer-events-none',
]);

export const cvaPlaceholder = cva(
  [
    'InputStyles-cvaPlaceholder',
    'absolute top-1.4 sm:top-1.6 left-1.6 sm:left-2.4',
    'text-cBlack text-opacity-[0.48] text-bodyM',
    'pointer-events-none',
    '[&>span]:text-cRed',
    'origin-top-left',
    'transition-transform duration-300',
    'z-[1]',
  ],
  {
    variants: {
      hasError: {
        true: ['text-cRed'],
        false: [''],
      },
      focused: {
        true: [
          'scale-[0.8] -translate-y-[0.9rem]',
        ],
        false: [''],
      },
    },
    defaultVariants: {
      hasError: false,
      focused: false,
    },
  }
);

export const cvaCaretDown = cva([
  'InputStyles-cvaCaretDown',
  'absolute right-1.6 sm:right-2.4 top-1/2 -translate-y-1/2 sm:-rotate-90 rotate-0',
  'w-1.6 h-1.6',
  'pointer-events-none',
]);

export const cvaInput = cva(
  [
    'w-full h-4.7 sm:h-5.6 ',
    'py-1 sm:py-1.6 px-1.6 sm:px-2.4',
    'text-cBlack text-bodyM',
    'border-2 border-transparent',
    'outline-none',
    'rounded-[1.6rem]',
    'transition-all duration-300',
    'placeholder:text-cBlack placeholder:text-opacity-[0.48]',
    'group-hover/input:bg-cMediumGreyOnWhite',
    'group-hover/dropdown:!border-transparent',
  ],
  {
    variants: {
      hasError: {
        true: ['border-cRed !bg-[#FF5E7F]/[8%]'],
        false: [''],
      },
      isFocused: {
        true: ['border-cMediumGreen'],
        false: '',
      },
      color: {
        white: ['bg-cWhite'],
        gray: ['bg-cLightGrey'],
      },
      textarea: {
        true: [
          'max-w-none w-full',
          'min-h-[12.5rem]',
        ],
        false: [],
      },
      select: {
        true: [''],
        false: [],
      },
    },
    defaultVariants: {
      hasError: false,
      color: 'gray',
      textarea: false,
      select: false,
    },
  }
);

export const cvaError = cva(
  [
    'text-[1.2rem]',
    'pt-[0.8rem]',
    'text-bodyM text-cRed',
  ],
  {
    variants: {
      isFocused: {
        true: ['text-gray-400'],
        false: ['text-cRed'],
      },
    },
  }
);
